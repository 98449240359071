/* poppins */
@font-face {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../fonts/poppins.woff2') format('woff2'),
        url('../fonts/poppins.woff') format('woff'),
}

// ########### General css ###########
* {
    font-family: 'poppins' !important;
}

